import React, { useState, useRef, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { useParams, useNavigate } from 'react-router-dom';
import { Input } from "web3uikit";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { defaultImgs } from "../defaultimgs";
import './Mint.css';
import Layout from "../components/layout";
import { getInstance } from "antd/lib/message";

const CreateSite = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { Moralis, account, user } = useMoralis();
  const inputFile = useRef(null);
  const [reactQuillRef, setReactQuillRef] = useState(null);

  const [selectedFile, setSelectedFile] = useState(defaultImgs[1]);
  const [siteUrl, setSiteUrl] = useState("");
  const [title, setTitle] = useState('');
  const [theFile, setTheFile] = useState();
  const [content, setContent] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [polygonTokenAddress, setPolygonTokenAddress] = useState('');
  const [bnbTokenAddress, setBnbTokenAddress] = useState('');
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote', 'code-block'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const onBannerClick = () => {
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    const img = event.target.files[0];
    setTheFile(img);
    setSelectedFile(URL.createObjectURL(img));
  };

  const createSite = async () => {
    const Sites = Moralis.Object.extend("Sites");
    const query = new Moralis.Query(Sites);
    
    let results = await query.equalTo("objectId", id).first();

    let myDetails = {};
    if (results === undefined) {
      myDetails = new Sites();
    } else {
      myDetails = results;
    }
    try {
      if (title){
        myDetails.set("siteTitle", title);
      }
  
      if (content){
        myDetails.set("siteContent", content);
      }
  
      const tokenAddressArr = tokenAddress.split(",");
      myDetails.set("siteTokenAddress", tokenAddressArr);

      const polygonTokenAddressArr = polygonTokenAddress.split(",");
      myDetails.set("sitePolygonTokenAddress", polygonTokenAddressArr);

      const bnbTokenAddressArr = bnbTokenAddress.split(",");
      myDetails.set("siteBnbTokenAddress", bnbTokenAddressArr);

      myDetails.set("owner", account);
  
      if (theFile) {
        const data = theFile;
        const file = new Moralis.File(data.name, data);
        await file.saveIPFS();
        myDetails.set("siteBanner", file.ipfs());
      }
      const newId = await myDetails.save();
      toast("You created new site");
      if (id === 'new') {
        navigate(`/create-sites/${newId.id}`);
      }
    } catch(e) {
      console.log(e)
    }

    // window.location.reload();
  }

  useEffect(() => {
    const getSite = async () => {
      const Sites = Moralis.Object.extend("Sites");
      const query = new Moralis.Query(Sites);
      query.equalTo("objectId", id);
      const results = await query.find();
      if (results.length > 0) {
        setTitle(results[0].attributes.siteTitle);
        setContent(results[0].attributes.siteContent);
        setTokenAddress(results[0].attributes.siteTokenAddress.join());
        setPolygonTokenAddress(results[0].attributes.sitePolygonTokenAddress.join());
        setBnbTokenAddress(results[0].attributes.siteBnbTokenAddress.join());
        if (results[0].attributes.siteBanner) {
          setSelectedFile(results[0].attributes.siteBanner);
        }
        setSiteUrl(`${process.env.REACT_APP_PUBLIC_URL}/${id}`)
      }
    }
    if (id !== 'new') {
      getSite();
    }
  }, [id]);

  const addEmbedCode = async() => {
    const editor = reactQuillRef.getEditor();
    const index = editor.getSelection().index || 0;
    console.log(index)
    editor.insertEmbed(index, 'video', 'https://www.youtube.com/embed/eGUEAvNpz48');
  }

  return (
    <Layout title="Create user's Page">
      
      <div className="create-site container">
        
        <div className="pfp">
          <p className="text-white">Header Banner</p>
          <div className="pfpOptions">
            <img src={selectedFile} onClick={onBannerClick} className="banner" alt=""/>
            <input
              type="file"
              name="file"
              ref={inputFile}
              onChange={changeHandler}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="my-4">
          <p className="text-white">Title</p>
          <Input
            name="TitleChange"
            width="100%"
            onChange={(e)=> setTitle(e.target.value)}
            value={title}
            className="text-white"
          />
        </div>
        <div className="mb-4">
          <p className="text-white">Content</p>
          <ReactQuill 
            // theme="snow" 
            ref = { (el) => { setReactQuillRef(el) } }
            value={content} 
            onChange={setContent} 
            modules={modules}
            formats={formats}
            style={{ background: 'white' }}
          />

          {/* <button onClick={addEmbedCode}>Add Video</button> */}
        </div>

        <div className="my-4">
          <p className="text-white">ETH Token Addresses</p>
          <Input
            name="TokenAddress"
            width="100%"
            onChange={(e)=> setTokenAddress(e.target.value)}
            value={tokenAddress}
            className="text-white"
          />
        </div>

        <div className="my-4">
          <p className="text-white">Polygon Token Addresses</p>
          <Input
            name="TokenAddress"
            width="100%"
            onChange={(e)=> setPolygonTokenAddress(e.target.value)}
            value={polygonTokenAddress}
            className="text-white"
          />
        </div>

        <div className="my-4">
          <p className="text-white">BSC Token Addresses</p>
          <Input
            name="TokenAddress"
            width="100%"
            onChange={(e)=> setBnbTokenAddress(e.target.value)}
            value={bnbTokenAddress}
            className="text-white"
          />
        </div>
        
        <div className="my-4">
          <p className="text-white">Site Link</p>
          <a className="text-white" href={siteUrl} target="_blink">{siteUrl}</a>
        </div>

        <button className="btn btn-primary w-100" style={{ marginTop: 20, marginBottom: 20 }} onClick={createSite}>Create Site</button>
      </div>

    </Layout>
  );
};

export default CreateSite;

