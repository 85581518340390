import React, { useEffect, useState } from "react";
import { useMoralis, useWeb3ExecuteFunction, useMoralisWeb3Api } from "react-moralis";
import { MintNFTABI, BurnNFTABI, MintNFTAddress, ChainId } from "../contract/constants";
import { toast } from "react-toastify";
import { MerkleTree } from 'merkletreejs';
import { keccak256 } from 'ethers/lib/utils';
import 'react-quill/dist/quill.snow.css';
import './Mint.css';
import Layout from "../components/layout";
import { Input } from "web3uikit";
import { MerkleWL1 } from '../whitelist/merkle1';
import { MerkleWL2 } from '../whitelist/merkle2';
import { MerkleWL4 } from '../whitelist/merkle4';
import { MerkleWL6 } from '../whitelist/merkle6';
import { MerkleWL12 } from '../whitelist/merkle12';

const Mint = () => {
  const { Moralis, account } = useMoralis();
  const Web3Api = useMoralisWeb3Api();
  const contractProcessor = useWeb3ExecuteFunction();

  const [mintAmount, setMintAmount] = useState(0);
  const [userNFTs, setUserNFTs] = useState([]);
  const [selectedNFTs, setSelectedNFTs] = useState([]);

  const getNFTs = async() => {
    let options = {
      chain: ChainId,
      address: account,
      token_address: MintNFTAddress
    };

    const Token2Data = await Web3Api.account.getNFTsForContract(options);
    let tmpTokenIds = [];
    Token2Data.result.map(item => {
      tmpTokenIds.push(item.token_id);
    })
    setUserNFTs(tmpTokenIds);
  }
  
  const mint = async () => {
    if (mintAmount === 0) {
      return toast.error("Please input correct amount");
    }
    const leafNodes1 = MerkleWL1.map(addr => keccak256(addr));
    const merkleTree1 = new MerkleTree(leafNodes1, keccak256, { sortPairs: true });
    let proof = ['0x0000000000000000000000000000000000000000000000000000000000000000', '0x0000000000000000000000000000000000000000000000000000000000000000'];
    await Promise.all(MerkleWL1.map((addr, index) => {
      if (addr.toLowerCase() === account.toLowerCase()) {
        proof = merkleTree1.getHexProof(leafNodes1[index]);
      }
    }));

    const leafNodes2 = MerkleWL2.map(addr => keccak256(addr));
    const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });
    await Promise.all(MerkleWL2.map((addr, index) => {
      if (addr.toLowerCase() === account.toLowerCase()) {
        proof = merkleTree2.getHexProof(leafNodes2[index]);
      }
    }));

    const leafNodes4 = MerkleWL4.map(addr => keccak256(addr));
    const merkleTree4 = new MerkleTree(leafNodes4, keccak256, { sortPairs: true });
    await Promise.all(MerkleWL4.map((addr, index) => {
      if (addr.toLowerCase() === account.toLowerCase()) {
        proof = merkleTree4.getHexProof(leafNodes4[index]);
      }
    }));

    const leafNodes6 = MerkleWL6.map(addr => keccak256(addr));
    const merkleTree6 = new MerkleTree(leafNodes6, keccak256, { sortPairs: true });
    await Promise.all(MerkleWL6.map((addr, index) => {
      if (addr.toLowerCase() === account.toLowerCase()) {
        proof = merkleTree6.getHexProof(leafNodes6[index]);
      }
    }));

    const leafNodes12 = MerkleWL12.map(addr => keccak256(addr));
    const merkleTree12 = new MerkleTree(leafNodes12, keccak256, { sortPairs: true });
    await Promise.all(MerkleWL12.map((addr, index) => {
      if (addr.toLowerCase() === account.toLowerCase()) {
        proof = merkleTree12.getHexProof(leafNodes12[index]);
      }
    }));

    let options = {
      contractAddress: MintNFTAddress,
      functionName: "mint",
      abi: MintNFTABI,
      params: {
        amount: mintAmount,
        proof: proof,
      },
      msgValue: Moralis.Units.ETH(0),
    }
    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        toast.success("You minted NFT successfully");
      },
      onError: (e) => {
        if (e.error)
          toast.error(e.error.message);
        else
          toast.error(e.message);
      },
    });
  }

  const burn = async () => {
    // const message = await Moralis.executeFunction(options);
    // console.log(message);
    const tokens = [...selectedNFTs];
    const tokenIdArr = [];
    tokens.map(token => {
      tokenIdArr.push(Number(token))
    })
    let options = {
      contractAddress: MintNFTAddress,
      functionName: "burn",
      abi: BurnNFTABI,
      params: {tokenIds: tokenIdArr}
    }
    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        toast.success("You burned NFTs successfully");
      },
      onError: (error) => {
        console.log(error.data.message)
      }
    });
  }

  const selectNFT = async (tokenId) => {
    let array = [...selectedNFTs];
    let index = array.indexOf(tokenId);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(tokenId);
    }
    setSelectedNFTs(array);
  }

  useEffect(() => {
    getNFTs();
  }, []);

  return (
    <Layout title="Mint">
      
      <div className="mint-page container">
        <div className="row">
          <div className="col-md-12">
          <p className="text-white">Mint Amount</p>
          <Input
            name="AmountChange"
            width="100%"
            onChange={(e)=> setMintAmount(e.target.value)}
            value={mintAmount}
            defaultValue={mintAmount}
          />
          </div>
          <div className="col-md-12">
            <button className="btn btn-primary w-100" style={{ marginTop: 20, marginBottom: 20 }} onClick={mint}>mint</button>
          </div>
        </div>
        <div className="row nft-list">
          {userNFTs.map((nft, index) => {
            return (
              <div className={`col-md-3 item ${selectedNFTs.includes(nft) ? 'selected' : ''}`} key={index} onClick={() => selectNFT(nft)}>
                <h2 className="text-white text-center mt-10 mb-10">{nft}</h2>
              </div>
            )
          })}
        </div>
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-primary w-100" style={{ marginTop: 20 }} onClick={burn}>burn</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mint;

