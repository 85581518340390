export const MintNFTAddress = '0xfD40999E961c5591db9c144a591d9025dBE711e2';
export const ChainId = '0x5';

export const MintNFTABI = [{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    },
    {
      "internalType": "bytes32[]",
      "name": "proof",
      "type": "bytes32[]"
    }
  ],
  "name": "mint",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}];


export const BurnNFTABI = [{
  "inputs": [
    {
      "internalType": "uint256[]",
      "name": "tokenIds",
      "type": "uint256[]"
    }
  ],
  "name": "burn",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}];