import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import './Profile.css';
import { defaultImgs } from "../defaultimgs";
import { useMoralis, useMoralisQuery, useMoralisWeb3Api } from "react-moralis";
import Collapse from '../images/collapse.webp';
import Layout from "../components/layout";
import TweetInFeed from "../components/TweetInFeed";
import Downloadables from "../components/Downloadables";
import Airdrops from "../components/Airdrops";


const Profile = () => {
  const { id } = useParams();
  const { Moralis, user, account: address } = useMoralis();
  const Web3Api = useMoralisWeb3Api();

  const [account, setAccount] = useState({});
  const [tabType, setTabType] = useState("posts");
  const [isOwner, setIsOwner] = useState(false);
  const [tokenGated, setTokenGated] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const results = await Moralis.Cloud.run("getUser", {address: id});
      setAccount(results[0])
      if (results[0].attributes.ethAddress === address) {
        setIsOwner(true);
      }
    }
    getUser();
  }, [id])

  useEffect(() => {
    const checkTokenGated = async () => {
      let isSettedToken = false;
      if (account.attributes.gateEthToken) {
        isSettedToken = true;
        let options = {
          chain: "0x1",
          address: address,
          token_address: account.attributes.gateEthToken.trim()
        };
        const Token2Data = await Web3Api.account.getNFTsForContract(options);
        if (Token2Data.total >0) {
          setTokenGated(true)
        } 
      }
      if (account.attributes.gatePolyToken) {
        isSettedToken = true;
        let options = {
          chain: `0x${Number('137').toString(16)}`,
          address: address,
          token_address: account.attributes.gatePolyToken.trim()
        };
        
        const Token2Data = await Web3Api.account.getNFTsForContract(options);
        if (Token2Data.total >0) {
          setTokenGated(true)
        } 
      }

      if (!isSettedToken) {
        setTokenGated(true);
      }
    }

    if (account.attributes) {
      checkTokenGated();
    }
  }, [account])

  return (
    <Layout title="Profile">
    {account && account.attributes ? (
      <>
        <img className="profileBanner" src={account.attributes.banner ? account.attributes.banner : defaultImgs[1]} alt="" />
        <div className="pfpContainer">
          <img className="profilePFP" src={account.attributes.pfp ? account.attributes.pfp : defaultImgs[0]} alt="" />
          <div className="profileName">{account.attributes.username.slice(0, 15)}</div>
          <div className="profileWallet">{`${account.attributes.ethAddress.slice(0, 4)}...
                ${account.attributes.ethAddress.slice(38)}`}</div>
          {user.attributes.ethAddress === id && (
            <Link to="/settings">
                <div className="profileEdit">Edit profile</div>
            </Link>
          )}
          <div className="profileBio">
          {account.attributes.bio}
          </div>
          <div className="profileTabs">
              <div className={`profileTab ${tabType === 'posts' ? 'active' : ''}`} onClick={() => setTabType("posts")}>
                Posts
              </div>
              <div className={`profileTab ${tabType === 'downloadables' ? 'active' : ''}`} onClick={() => setTabType("downloadables")}>
                Downloadables
              </div>
              <div className={`profileTab ${tabType === 'airdrops' ? 'active' : ''}`} onClick={() => setTabType("airdrops")}>
                Airdrops
              </div>
              <div className={`profileTab ${tabType === 'community' ? 'active' : ''}`} onClick={() => setTabType("community")}>
                Community
              </div>
          </div>
        </div>
        {tabType === 'posts' && (
          <TweetInFeed ethAddress={id}></TweetInFeed>
        )}
        {tabType === 'downloadables' && (
          <Downloadables ethAddress={id} isOwner={isOwner} tokenGated={tokenGated}></Downloadables>
        )}
        {tabType === 'airdrops' && (
          <Airdrops ethAddress={id} isOwner={isOwner} tokenGated={tokenGated}></Airdrops>
        )}
      </>
    ) : ''}
    </Layout>
  );
};

export default Profile;

