export const MerkleWL6 = [
  "0x287187b925129918f69da6b7ee2fb5ce37d63dde",
  "0x4adb9cf90c2a3af77a23199d5d6398e3d43d78c8",
  "0x72fde4d8f571a6ebdc1d6509e19a3a1e9ebea963",
  "0x4303d0d654abeE80D527976AEE8D788A9711bB95",
  "0xc1F8e0A9b89bF92a274455790E0AE686AD6969D0",
"0x08081f1eEADF6e2aAe60ba89f80B030538d0D3a3",
"0x3eC4483CDB2bCcC637EF2C94e8F6EEEB8247823b",
"0x7051D33aAdaDb8c7F83bC3D7EFBb015ceF3257Df",
"0x06C1bae17aafE75F40dfB86667E586Ef837A8fF4",
"0x1dF24622941C97840BA53e2b165d4BCd0d86464A",
"0x35365aE5c8557EA978A63b35a6459f2560e809B9",
"0x6bD7411E6E0A156300bEe30aA1363548fce8716F",
"0xd49214b455698258B5995416B3e6a5c8ffabFcd8",
"0x287187B925129918F69Da6B7Ee2Fb5Ce37D63DdE",
"0xb5DE7db95d7cE30c5eCDeAF1AADe046E998580fd",
"0xCE0ce10BC6251DCDE4CAFaA189A4a9539731506A",
"0x7568Da13Df70012A38e359449Dc30D1cde9F646c",
"0x597005d6f2dDE976b46cBD5550DB9FAB5Dae173C",
"0x02A54c98EaB42934066EfA7655aFF819FDd3eEfF",
"0x3e7dC5c9D4d919c56b14f337514743E85A71aD1a",
"0xcbFb9a917C2b45fBD8E820E66602Ef26dfF9cD40",
"0xe713794d7e3083687b93b64d5e5b15c84dc6dbd5",
"0xdD704Cc4AC9A043c8Ec709751d568d78E2dCb571",
"0x66c7D364cA4Dd5858336D1d55208a78ebac164A8",
"0x0376De0C2c8A2c8916Dab716d47D9652087C2918",
"0xEd4970f657C11dE6902247227b29114072BB3b2b",
"0x5CF92AD54257519240206f4d4F61DB4f87Fc7375",
"0x7858F91c6752400F2234584A25006335bE6D09Bb",
"0x719f17Ed85c9208F5fA7dbF4067bCFFa6D63F508",
"0xB13C945bE8530FBC801c4121153AE07F84F629FD",
"0xD1EfC9fBe559607D17B6313bf2Ad3DC02B612FeE",
"0x90b56d0e27e74c3c5e66ebfcaf12dc5ecf0665dd",
"0xd803bA08bc87a7B765956d13d50Bc5d1f37dB936",
"0x6F10073D9154967B209F5c1cf578E42cd7A51b70",
"0xe22bE3FfAF98D7B17b0Db8CD46376385Bcde3B66",
"0xc08B34a4d7af7773e0199CC953134689Af70F3d1",
"0x4aDB9Cf90c2a3aF77A23199d5D6398e3d43d78C8",
"0xdb68A37014FaeaAB36f3d244f9649A6877d3b045",
"0x63f134562DfAD5BC72f0f9C7101b2eF54b5444D5",
"0xc8d2f3FF5D8fEac92b77b792B47C11Eb27369eDA",
"0x7858F91c6752400F2234584A25006335bE6D09Bb",
"0x475545D483e132218a72273268654c3960C1f28C",
"0xc08B34a4d7af7773e0199CC953134689Af70F3d1",
"0xeA35C92FC2b2D0ecA27FAE3E06F26a801A1Ff469",
"0xb9BB10D46ef46068b876f0FFA27016eca5deE8ab",
"0xDE6AcA86bf65ddF080B9604b89b5Bd5bC698978F",
"0x0289C6e3D10C622873C1671bD698AD83e9436c47",
"0x77C8eCD2f2f64a71E56E021955f75906F351C19a",
"0x08081f1eEADF6e2aAe60ba89f80B030538d0D3a3",
"0x63f134562DfAD5BC72f0f9C7101b2eF54b5444D5",
"0xce431F34dFd634E8eD90e54875DD4f9f655C04e7",
"0xc1F8e0A9b89bF92a274455790E0AE686AD6969D0",
"0xe22bE3FfAF98D7B17b0Db8CD46376385Bcde3B66",
"0xd49214b455698258B5995416B3e6a5c8ffabFcd8",
"0x5CF92AD54257519240206f4d4F61DB4f87Fc7375",
"0x475545D483e132218a72273268654c3960C1f28C",
"0x117972f869f12fd5Bd31622eEB12e166ACAfB6c8",
"0xFa3217A796438168a3918E665a04B4c89005ad67",
"0x48a78A1a2D5E02302285cd3d41336D1e54E2F018",
"0x1C53cED8017428698A8a537c6fEE95F84FC7C024"
]