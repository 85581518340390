import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import {toast} from 'react-toastify';
import './Site.css';

const Site = () => {
  const { id } = useParams();
  const { Moralis, account: address } = useMoralis();
  const Web3Api = useMoralisWeb3Api();
  const [account, setAccount] = useState({});
  const [tokenGate, setTokenGate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getSite() {
      setLoading(true)
      try {
        const Sites = Moralis.Object.extend("Sites");
        const query = new Moralis.Query(Sites);
        if (id) {
          query.equalTo("objectId", id);
        }
        const results = await query.first();
        let isSettedToken = false;
        if (results) {
          setAccount(results)
          // check the token gate on eth.
          await Promise.all(results.attributes.siteTokenAddress.map(async ethToken => {
            if (ethToken) {
              isSettedToken = true;
              let options = {
                chain: "0x1",
                address: address,
                token_address: ethToken.trim()
              };
              const Token2Data = await Web3Api.account.getNFTsForContract(options);
              if (Token2Data.total >0) {
                setRefresh(true)
              } 
            }
          }));

          // check the token gate on polygon.
          await Promise.all(results.attributes.sitePolygonTokenAddress.map(async polygonToken => {
            if (polygonToken) {
              isSettedToken = true;
              let options = {
                chain: `0x${Number('137').toString(16)}`,
                address: address,
                token_address: polygonToken.trim()
              };
              
              const Token2Data = await Web3Api.account.getNFTsForContract(options);
              if (Token2Data.total >0) {
                setRefresh(true)
              } 
            }
          }));

          // check the token gate on bsc.
          await Promise.all(results.attributes.siteBnbTokenAddress.map(async bnbToken => {
            if (bnbToken) {
              isSettedToken = true;
              let options = {
                chain: `0x${Number('56').toString(16)}`,
                address: address,
                token_address: bnbToken.trim()
              };
              
              const Token2Data = await Web3Api.account.getNFTsForContract(options);
              if (Token2Data.total >0) {
                setRefresh(true)
              } 
            }
          }));

          if (!isSettedToken) {
            setRefresh(true)
          }
        }
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)
      }
    }
    getSite();
  }, [id]);

  useEffect(() => {
    if (refresh) {
      setTokenGate(true)
    }
  }, [refresh])

  return (
    <>
    {!loading && (
      <>
        {tokenGate && account.attributes && (
          <div className='site-page container'>
            <img className='w-100' src={account.attributes.siteBanner}></img>
            <h1>{account.attributes.siteTitle}</h1>
            <div dangerouslySetInnerHTML={{ __html: account.attributes.siteContent }} />
            {/* {account.attributes.siteContent} */}
          </div>
        )}
        {!account.attributes && (
          <h2 className='text-center mt-5'>No content</h2>
        )}
        {!tokenGate && account.attributes && (
          <h2 className='text-center mt-5'>You are not allowed to access this site</h2>
        )}
      </>
    )}
    {loading && (
      <div className='loader' style={{ marginTop: 100 }}></div>
    )}
    </>
  )
}

export default Site;