export const MerkleWL2 = [
  "0x723a42724ca4171cd8e58cbb58c5556755bc0c48",
"0xd25b3b57968ed74e30ae1b39c9b1df3e61a92da8",
"0x82a3f2b08af847e511ded5e509c1ea27b5764c9b",
"0xa1f37db39063b34b0dba90d71c59beb4432a977d",
"0x031f2147e9ec8d00bd463cdcd263d3646d91ac3e",
"0x33be5e9723187141a089432da6d1583ac14bbcc4",
"0x70edeb4f993f5c9991aab0d843a28f383ae9b199",
"0xa8b0f9df1d622dda1f4e73c87ac0674b28b10a2e",
"0x08081f1eeadf6e2aae60ba89f80b030538d0d3a3",
"0x4adb9cf90c2a3af77a23199d5d6398e3d43d78c8",
"0xcbfb9a917c2b45fbd8e820e66602ef26dff9cd40",
"0x44955535a4217e38b68ebc1d82521f5d7fbfd07e",
"0x0ee58839510385199452bdd4bc90d3c712d50bfd",
"0x475545d483e132218a72273268654c3960c1f28c",
"0xe713794d7e3083687b93b64d5e5b15c84dc6dbd5",
"0x96571d014f18600e222f857e76d4f2ac2de5c7bf",
"0xb65e6aa60a7abb93ca4acc15dfd2e81be5ac5eac",
"0xab7fd815c1644a0fd330043766f82b97d82897aa",
"0x2d3dbcb2929afe8e9aa700ef41d14fff85bb91f5",
"0xf4b07f6d9fd5b9ff0c86169bef013014dbb4eacf",
"0xf81ca0c17423ef1e918daee6035eeabb99401a89",
"0xb3a9f76a1a87d7b5f8e8d5b1dd32ba9cf9b337b9",
"0x41a8f3b4de59fbe44772cc94a445cfaa8f89eb00",
"0xa478e7a8792b584845cfe27819761e16422154b4",
"0xa501ec048fb89e3bd3e1354751a2c04e953afb6f",
"0x7858f91c6752400f2234584a25006335be6d09bb",
"0xdb68a37014faeaab36f3d244f9649a6877d3b045",
"0xbf587d2527a4d956a57f777bc310de9385a8b2db",
"0x31387099cd705d59b6f2f0efa80a210ff5bbcab7",
"0xc311e25c735c2f59f93d540049261ea8a2529b6f",
"0xe0eca6190c66033b6159c8ab4b532966df5b6d8e",
"0x2fa0bd091b78d9ef6a2fa308a562deb8b5c5019b",
"0x00ca2f23a40c1bd3027f5e0b9add9752eb1d76d6",
"0x48a78a1a2d5e02302285cd3d41336d1e54e2f018",
"0x02a5c980029cb470ac89df2e2de1cf453aee6558",
"0x658b3530c9d0dedbb09509fb5f29fc82ff3c88af",
"0x747a0609771bca1bf7ab30358a3b9b9fbb7a2e74",
"0xd803ba08bc87a7b765956d13d50bc5d1f37db936",
"0x5ae900a7bca4b379ad8786c0f75dea50b9778567",
"0x3ec4483cdb2bccc637ef2c94e8f6eeeb8247823b",
"0xc5f1467bd2aba1bf37056b69b86dcaa7771c1eb9",
"0x11f0443346e4430edeef1ee86bd72bc9fa3f3712",
"0x7051d33aadadb8c7f83bc3d7efbb015cef3257df",
"0x01aa68f8a8f0534fff9f1b0de0ac6b1c9e158dc7",
"0x05b783aad022ee0386010f88e3b70b42a782d767",
"0x011e2747f5e393e67ce0372cb9cfbd0b9a4c8f12",
"0x1122dd10264bfdc961e4831167783200249564fc",
"0x02a5c980029cb470ac89df2e2de1cf453aee6558",
 "0x08081f1eeadf6e2aae60ba89f80b030538d0d3a3",
"0xc8d2f3FF5D8fEac92b77b792B47C11Eb27369eDA",
"0xf81ca0c17423ef1e918daee6035eeabb99401a89",
"0xf4b07f6d9fd5b9ff0c86169bef013014dbb4eacf",
"0xe713794d7e3083687b93b64d5e5b15c84dc6dbd5",
"0xe0eca6190c66033b6159c8ab4b532966df5b6d8e",
"0xdb68a37014faeaab36f3d244f9649a6877d3b045",
"0xcbfb9a917c2b45fbd8e820e66602ef26dff9cd40",
"0xd25b3b57968ed74e30ae1b39c9b1df3e61a92da8",
"0xd803ba08bc87a7b765956d13d50bc5d1f37db936",
"0x00ca2f23a40c1bd3027f5e0b9add9752eb1d76d6",
"0x01aa68f8a8f0534fff9f1b0de0ac6b1c9e158dc7",
"0x93A58462FB713A0245B5c4Ff2A987c83DB18dB10",
"0x48a78a1a2d5e02302285cd3d41336d1e54e2f018",
"0x850D5c6C13A3041E4010865c693a1F1685f22688",
"0xf5a5C960515b23b668964FBC6D36714Bfd837373",
"0x35365aE5c8557EA978A63b35a6459f2560e809B9",
"0xBD4C340FBdA90CdbdFc0Bf51Ab970080662Fc1B2",
"0x54ccbb5c380f2543bf210bebb0784878784868f7",
"0x1d4f835239e82b0d1044c56765e64b9be9233878",
"0x4879c3c1858c06160bed9dcad02fe051710c93a2",
"0x3ce630e9fc9567e49ba987b42cff14f322044984",
"0xfafca7a937b5c642fde7a75b1141049b805961f6",
"0x0ee58839510385199452bdd4bc90d3c712d50bfd",
"0x7cfb6471d2a9913b7d27d3f3983751efa028eea9",
"0x089f703993f0aeaab1e5fe6a800f3fd09e7745f3",
"0x9f3583ded9ceef33b058deaa4d6e127728b4f5c9",
"0x155f541ed89e19a2275e3805900d06bc956e29aa",
"0xe713794d7e3083687b93b64d5e5b15c84dc6dbd5",
"0xeac5f9b3cd48123a69fe69ce93a7f58100a56552",
"0x4cdd7811ccedb0ffd3808b586bcafd6ab0acb313",
"0x80d13ddd5b619fb89fd5c6bb5ae7bdc1d70e1728",
"0xa2a56e1266695143611b0fc84c5b6b8fc8e08658",
"0xbf587d2527a4d956a57f777bc310de9385a8b2db",
"0x31387099cd705d59b6f2f0efa80a210ff5bbcab7",
"0xc311e25c735c2f59f93d540049261ea8a2529b6f",
"0xe0eca6190c66033b6159c8ab4b532966df5b6d8e",
"0xc08b34a4d7af7773e0199cc953134689af70f3d1",
"0xaB968fF9A702Aa27A2B39c5eCEBDcEbBB1D9f9e7",
"0x1be9021726e5eac98bd3079b49a8de6e60744e04",
"0xaec3d65b67bd9693187f471f12d3e30b28f44fdf",
"0xab7fd815c1644a0fd330043766f82b97d82897aa",
"0xce0ce10bc6251dcde4cafaa189a4a9539731506a",
"0x7858f91c6752400f2234584a25006335be6d09bb",
"0x49e785e18cf95c83a4d0e44bfbb6549afeed87ca",
"0x72fde4d8f571a6ebdc1d6509e19a3a1e9ebea963",
"0xabf9c98e6b7e77f07f28e94e7f83f76bb5129c77",
"0x8c3378a0893d367016b0aab9e565ca55375b341f",
"0x88e4ed76ab146ab4f9fe9bf1deb974aef83e9293",
"0x858bc5e9d9fac29c9256491385dc2d39090f635e",
"0x55a43d4ab794b2b0d0dc60ba6f68571be6e265a2",
"0x3b93e177cdc156c5154c99296c40affaab26a678",
"0xc502b4e8346524cd679fbbada962317c8f0e1291",
"0x69fd02c1cf7659d3d095c4ce73b5d5c23886b5f6",
"0x280fd04ec953ebc75a3e70793dcb2745f0646366",
"0x1bf0197ae08441711d23a25dad3292541cbd6374",
"0x249686e9a37a89e3b857c5e950d1426ee6b5a771",
"0x6F10073D9154967B209F5c1cf578E42cd7A51b70",
"0x34bbf3b83f82342f16ac3c5d7d3256f9be9441bc",
"0x4201bf54b441ecac27d8e3ebecac62829e4d3849",
"0xd1b8aeef1fb38c9117a66731aebc75f1f9a727be",
"0xf81ca0c17423ef1e918daee6035eeabb99401a89",
"0x28e3d831cb9939a98ffc8f29f9b6d1afbb410af6",
"0xa7e19134ec11a2db076b0e2b4210f13f6cacfcdc",
"0x0B5C326d1a1846256613F57c9742f8b8d37578D5",
"0x1be58857d68a9874f4728d94b0eb294ffd086582",
"0x08081f1eeadf6e2aae60ba89f80b030538d0d3a3",
"0x70edeb4f993f5c9991aab0d843a28f383ae9b199",
"0x00ca2f23a40c1bd3027f5e0b9add9752eb1d76d6",
"0x969c00942e13358a5b704f5105fb41017c15bcaf",
"0x1420477b898e0fc2dd7caaa3bac49f7fcf2db551",
"0x84d4558ad15cde5ae6b68fd72b00c8e97734c641",
"0x875773b396db5225fa005836b1c385198a9b05e0",
"0xb4c3a8ca8fe617a990df8ad7d8307642161cd074",
"0xdb68a37014faeaab36f3d244f9649a6877d3b045",
"0x612a22d86ca9da37f2dcefcd2d4ca5167a14303c",
"0xb3a9f76a1a87d7b5f8e8d5b1dd32ba9cf9b337b9",
"0xa8879c580a54f190ed53b43d30de269097ad7543",
"0x0f200bc6ef49024e26aacfde0306c282e5711da8",
"0xc1f8e0a9b89bf92a274455790e0ae686ad6969d0",
"0xcd878455231d9eff5ecd9232106b87cd732b64ef",
"0x03cefb1cac97b9b9d749aac9ec94c7ac40f192ed",
"0x083627a7ab205145e4152b1775292bf0d818578d",
"0xdd704cc4ac9a043c8ec709751d568d78e2dcb571",
"0xa501ec048fb89e3bd3e1354751a2c04e953afb6f",
"0x82a3f2b08af847e511ded5e509c1ea27b5764c9b",
"0xf438f8f008aff430ebb516b9ecbe285d91146c5a",
"0x01d8521a5b87bb1ff1dc7eed56b644647beb67eb",
"0x77c8ecd2f2f64a71e56e021955f75906f351c19a",
"0x02a5c980029cb470ac89df2e2de1cf453aee6558",
"0x747a0609771bca1bf7ab30358a3b9b9fbb7a2e74",
"0x2e3877e80df0b56e3980ac396797f2c7226a3543",
"0x5e0fdc5a4a74c962c0c96fd457bb494b10d84a50",
"0x287187b925129918f69da6b7ee2fb5ce37d63dde",
"0x8ffa8d00db5df56a20c30b737c5efdaabe140df4",
"0x1a41ec571ec8fc815febf81fcbaf9d7f7ec98849",
"0xb5de7db95d7ce30c5ecdeaf1aade046e998580fd",
"0x5f3f579d84f776826908ff1f3f1521477dad5f9f",
"0x2d801b634331d0b260734b6f1d8064c6101bacc4",
"0xd803ba08bc87a7b765956d13d50bc5d1f37db936",
"0xb1e3cba438f081fc7bb3ea15149e7630aef41386",
"0xa4a5837f7183fc45a84f90a4072fb49107b12a0a",
"0x73dababdeddf0a3cdf7175c188ee622c5917c7e0",
"0x5ae900a7bca4b379ad8786c0f75dea50b9778567",
"0x995f6fd07baea9c9fb9babf418d2ae52b91c5a8a",
"0xe0d3781afcf8439a0aece21fad0c716ec4a58e2a",
"0x21a4bf6d4dfc8751c2442c76264b6f47e8edc63d",
"0x7568da13df70012a38e359449dc30d1cde9f646c",
"0x428505820d80d0c0c022cc5288bf8529812bc59b",
"0xa9cf502221a0b15e06053fe8240a6db272c1cc42",
"0xb13c945be8530fbc801c4121153ae07f84f629fd",
"0x9f986d807d0794eae8bbfabc0ae5981cad2d7ec1",
"0xb7496e5d97f81924256152ad1ed22d8e91959343",
"0x6bd7411e6e0a156300bee30aa1363548fce8716f",
"0x8427221a678a93baa14dd9dfe7c1c70ff496c6c2",
"0x73b41fafc67fbee0afd35eaeaba76e7468083f07",
"0xb7ca7b34a11e13b36c9eb9cd65330cedff75f099",
"0x19e5038d856faa9d0e0faf73efb369e97073c44a",
"0x11220dcfc3f7f5b602b471863664f00f1b5117f4",
"0x3ebdb522301913a5203315c8a840a9ee99ce9eee",
"0x38802a1c483c03bc5f7f8bfe7d789c8f8cde71c0",
"0x184e1642e3afcd1f4fdcc584cc70f969fae3e3e1",
"0x117972f869f12fd5bd31622eeb12e166acafb6c8",
"0xa8b0f9df1d622dda1f4e73c87ac0674b28b10a2e",
"0x45b6e9bb078a57565268d870855d3b92313f24c6",
"0xc5f1467bd2aba1bf37056b69b86dcaa7771c1eb9",
"0x302bb37570861d1e519c4d383d988cb6bdfd6a1a",
"0x7051d33aadadb8c7f83bc3d7efbb015cef3257df",
"0x767973eaf3cff050dec265864a5467ae64f5a5e4",
"0xebd3a85c56dafaf0843103c4e0464f814d764b69",
"0x0c03361423dca95f837959a87c5e1370b1ebca26",
"0xaf30806ed985dde18a4fa6fc5d775e40b7e156d8",
"0xc3350ad1d08f85acab2025657fbee18c1c98cce1",
"0x01aa68f8a8f0534fff9f1b0de0ac6b1c9e158dc7",
"0x86f0d6712cd1b59ae7d2bd7c7777dcdf3de252f2",
"0x2df01add9afd2604dc970c1b2c9e5532cc1bb2d1",
"0xf1be15bd420cff8cae2651e1fb678944e66c269c",
"0x4a457277d0b79684ef266bafdef2a0abcd6db991",
"0x6d9378e915fd5d8b94e6bd34855c90d88e74fc7c",
"0x6bd276363285b9fbd4b01636e3934d881e4a7d58",
"0xbaaabce9d8b6e0e7b26e107f33ddfc7bd582e301",
"0x05b783aad022ee0386010f88e3b70b42a782d767",
"0x37ef016ab9cc8efc2c730ba48c60aadc3ad41dac",
"0x5781795ff2d9f8f66b03a91af05f3c651418777f",
"0xc1a0a95eeecabebb15569a602bbbc5ffa69a23ea",
"0xa819c50d511187ce0f6aa352427586d6d0c187f7",
"0xe6c089b739aa7e84e1d50709392052aecca5d67c",
"0x97c8becdb6bdb1bfc0a789cc240f0e126c9a3feb",
"0xae1d7ed78c65cc05a11d832de7f799ff77c8121f",
"0xedacba08cb62d01b0b1bb7f1c31b265a070f899b",
"0x7dc0bd67cef41ebfcf7547fd9f0923f5b47b62ae",
"0xfbc823839a736ad6edb2736354cf04b24677d2b9",
"0x27ea7c1ebb7129ba574a52bcfcd12329639cb5b6",
"0xa5d6074d264bc334abd485339b4c98f275afdedc",
"0x71c5315068d2ef419c5b39633d94603aab5c0249",
"0xb6ea791ee66c6813798743a5e5f11aff170a1eda",
"0xfa3217a796438168a3918e665a04b4c89005ad67",
"0x1122dd10264bfdc961e4831167783200249564fc",
"0x40da2891c23748d1e521c563bb7c71d822f17d3e",
"0x1b6dCBA4367881Fb749c40245c52f91F00F212aA",
"0x93A58462FB713A0245B5c4Ff2A987c83DB18dB10"
]