import React, { useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import { Link } from 'react-router-dom';
import Layout from '../components/layout';
import { toast } from 'react-toastify';

const SiteList = () => {
  
  const { Moralis, account } = useMoralis();

  const [siteList, setSiteList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function getSites() {
      if (account) {
        try {
          const Sites = Moralis.Object.extend("Sites");
          const query = new Moralis.Query(Sites);
          query.equalTo("owner", account);
          const results = await query.find();
          console.log(results)
          setSiteList(results);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getSites();
  }, [ account, refresh ]);

  const remove = async (siteId) => {
    const Sites = Moralis.Object.extend("Sites");
    const query = new Moralis.Query(Sites);
    query.equalTo("objectId", siteId);
    const results = await query.first();
    if (results) {
      try {
        await results.destroy();
        toast("Removed Your site.");
        setRefresh(!refresh)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <Layout title="Site List">
      <div className='row'>
        <Link to="/create-sites/new" className='btn btn-primary'>Add New Site</Link>
      </div>
      <div className='row mt-5' style={{ padding: '10px 20px' }}>
        {siteList.map((site, index) => {
          return (
            <div className='col-md-12 border' key={index} style={{ margin: '10px 0' }}>
              <div className='d-flex justify-content-between' style={{ padding: '10px' }}>
                  <Link to= {`/create-sites/${site.id}`}>
                    <h3 className='text-white'>{site.attributes.siteTitle}</h3>
                  </Link>
                  <button className="btn btn-success" onClick={() => remove(site.id)}>Remove</button>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default SiteList;