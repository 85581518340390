import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { Input } from "web3uikit";
import { defaultImgs } from "../defaultimgs";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { toast } from "react-toastify";

const Downloadables = ({ ethAddress, isOwner, tokenGated }) => {
  const { Moralis, account, user } = useMoralis();
  const Web3Api = useMoralisWeb3Api();

  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const inputFile = useRef(null);
  const [selectedFile, setSelectedFile] = useState(defaultImgs[1]);
  const [theFile, setTheFile] = useState();

  const flipRefresh = () => {
    setRefresh(!refresh);
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const toggleModal = () => {
    setIsOpen(!modalIsOpen)
  }

  const onBannerClick = () => {
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    const img = event.target.files[0];
    setTheFile(img);
    setSelectedFile(URL.createObjectURL(img));
  };

  const saveData = async () => {
    const Downloadables = Moralis.Object.extend("Downloadables");
    const Instance = new Downloadables();

    Instance.set("owner", ethAddress);
    Instance.set("isDeleted", false);
    if (title) {
      Instance.set("title", title);
    }

    if (theFile) {
      const data = theFile;
      const file = new Moralis.File(data.name, data);
      await file.saveIPFS();
      Instance.set("file", file.ipfs());
    }
    try {
      await Instance.save();
      toast.success("saved successfully");
      flipRefresh();
    } catch (e) {
      console.log(e)
    } finally {
      setIsOpen(false);
    }
  }

  const fetchData = async () => {
    const Downloadables = Moralis.Object.extend("Downloadables");
    const query = new Moralis.Query(Downloadables);
    if (ethAddress) {
      query.equalTo("owner", ethAddress);
    }
    query.equalTo("isDeleted", false);
    const results = await query.find();
    setList(results);
  }

  const removeDownloadables = async (id) => {
    const Downloadables = Moralis.Object.extend("Downloadables");
    const query = new Moralis.Query(Downloadables);
    let results = await query.equalTo("objectId", id).first();
    results.set("isDeleted", true);
    try {
      await results.save();
      toast.success("deleted successfully");
      flipRefresh();
    } catch (e) {
      console.log(e)
    } finally {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (tokenGated || isOwner) {
      fetchData();
    }
  }, [refresh, tokenGated, isOwner]);

  return (
    <Container>
      {isOwner && (
        <div className="pull-right">
          <button className="btn-quark" style={{ marginTop: 10 }} onClick={openModal}>Create New</button>
        </div>
      )}
      {(!isOwner && !tokenGated) && (
        <h4 className="text-center">you don't have access to this</h4>
      )}
      <Row>
        {list.map((item, index) => {
          return (
            <Col md={3} xs={6} key={index}>
              <Card>
                <CardImg top width="100%" src={item.attributes.file} alt="" />
                <CardBody>
                  <CardTitle>{item.attributes.title}</CardTitle>
                  <Button onClick={() => removeDownloadables(item.id)}>Remove</Button>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
      <Modal isOpen={modalIsOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Downloadables</ModalHeader>
        <ModalBody>
          <Input
            label="Title"
            name="titleChange"
            width="100%"
            onChange={(e)=> setTitle(e.target.value)}
          />

          <div className="pfp">
            Image
            <div className="pfpOptions">
              <img
                src={selectedFile}
                onClick={onBannerClick}
                className="banner"
                alt=""
              ></img>
              <input
                type="file"
                name="file"
                ref={inputFile}
                onChange={changeHandler}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveData}>Save</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  )
}

export default Downloadables;
